/* @font-face kit by Fonts2u (https://fonts2u.com) */

@font-face {
  font-family: 'Archive';
  src: url('./Archive.eot?') format('eot'), url('./Archive.woff') format('woff'),
    url('./Archive.ttf') format('truetype'),
    url('./Archive.svg#Archive') format('svg');
  font-weight: normal;
  font-style: normal;
}
