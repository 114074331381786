@font-face {
  font-family: 'Teko';
  src: url('./Teko-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Teko';
  src: url('./Teko-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
