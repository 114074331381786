.replay_viewer {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.replay_viewer,
.replay_viewer svg * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.replay_viewer text {
  font: 11px sans-serif;
  fill: rgba(222, 222, 222, 0.8);
}

.fps,
.ticks {
  color: #fff;
  font-family: sans-serif;
  font-size: 11px;
}

#ctwinchance {
  color: #fff;
  font-family: sans-serif;
  font-size: 40px;
}

#ctwinchancebar {
  fill: #fff;
}

#twinchancebar {
  fill: #7e8082;
}

#ctwinchancebar.twinning {
  fill: #c2600f;
}

#ctwinchancebar.ctwinning {
  fill: #141fb8;
}

#ctwinchancebar.hidden {
  display: none;
}

#twinchancebar.hidden {
  display: none;
}

.shotgradient_stop1 {
  stop-color: rgb(255, 255, 255);
}

.shotgradient_stop2 {
  stop-color: rgb(255, 255, 255);
  stop-opacity: 1;
}

.shotgradient_stop3 {
  stop-color: rgb(180, 120, 0);
  stop-opacity: 0;
}

.viewgradient_stop1 {
  stop-color: rgb(255, 255, 255);
  stop-opacity: 0.7;
}

.viewgradient_stop2 {
  stop-color: rgb(180, 120, 0);
  stop-opacity: 0;
}

circle.timer {
  fill: rgba(180, 120, 0, 0.1);
  stroke: rgba(180, 120, 0, 0.6);
  stroke-width: 8px;
}

circle.blind {
  fill: rgba(255, 255, 255, 0.8);
  stroke: none;
}

circle.position {
  fill: rgb(255, 196, 0);
  stroke: #222;
  stroke-width: 2px;
}

circle.user {
  fill: rgb(255, 196, 0);
  stroke: rgba(16, 16, 16, 0.5);
  stroke-width: 1px;
}

circle.hurt {
  fill: rgb(255, 0, 0);
  stroke: #222;
  stroke-width: 2px;
  display: none;
}

rect.orientation {
  fill: #222;
}

rect.shot,
rect.throw {
  fill: url(#shotgradient);
  display: none;
}

path.swipe,
.shot.hit {
  stroke: url(#shotgradient);
  stroke-width: 2;
  fill: none;
}

path.fov {
  /* fill: rgba(222, 222, 222, .1); */
  fill: url(#viewgradient);
  opacity: 0.5;
}

.dead polygon.hurt {
  fill: rgb(180, 0, 0);
}

.dead polygon.grave {
  fill: rgb(255, 196, 0);
  opacity: 0.5;
}

text.nametag {
  font: 11px sans-serif;
  fill: rgba(222, 222, 222, 0.8);
}

rect.namebg {
  fill: rgba(0, 0, 0, 0.2);
  display: none;
}

.health .frame {
  fill: rgba(222, 222, 222, 0.3);
  stroke: #222;
  stroke-width: 2px;
  paint-order: stroke;
}

.health .hurt {
  fill: rgb(255, 0, 0);
  display: none;
  opacity: 1;
}

.health .fill {
  fill: rgb(0, 150, 0);
  stroke: none;
  opacity: 0.5;
}

.player .bomb {
  display: none;
}

.player .bomb rect.bombbg {
  fill: rgb(180, 120, 0);
  stroke: #222;
  stroke-width: 1px;
}

.scrubber_frame {
  fill: #444;
  stroke: #000;
  stroke-width: 1px;
}

.scrubber_fill {
  fill: rgb(180, 0, 0);
  stroke: #000;
  stroke-width: 1px;
}

.weapon {
  fill: rgb(180, 120, 0);
}

.weapon path {
  stroke: #222;
  stroke-width: 6px;
  paint-order: stroke;
}

.shotgradient_alt_stop1 {
  stop-color: rgb(255, 255, 255);
}

.shotgradient_alt_stop2 {
  stop-color: rgb(255, 255, 255);
  stop-opacity: 1;
}

.shotgradient_alt_stop3 {
  stop-color: rgb(60, 120, 180);
  stop-opacity: 0;
}

/* grenades */

.grenade.active {
  opacity: 0.5;
}

/*flashbang*/
.grenade.grenade43 {
  fill: #36f;
}

/*hegrenade*/
.grenade.grenade44 {
  fill: #800;
}

/*smokegrenade*/
.grenade.grenade45 {
  fill: rgba(130, 130, 130, 1);
}

.grenade.grenade45.active.t {
  stroke: rgb(255, 196, 0);
}

.grenade.grenade45.active.ct {
  stroke: rgb(0, 188, 255);
}

.grenade.grenade45.active {
  opacity: 1;
  fill: rgba(130, 130, 130, 0.6);
  stroke: rgb(180, 180, 180);
  stroke-width: 2px;
}

/*decoy*/
.grenade.grenade47 {
  fill: rgb(40, 120, 0);
}

.grenade.grenade47.active {
  opacity: 1;
  stroke: #222;
  stroke-width: 2px;
}

/* inferno */
.grenade.grenade46,
.grenade.grenade48 {
  fill: rgb(220, 90, 0);
}

.grenade.grenade46.active,
.grenade.grenade48.active {
  opacity: 1;
  fill: rgba(220, 90, 0, 0.5);
  stroke: rgb(220, 90, 0);
  stroke-width: 2px;
}

.killfeed .killer {
  font: 16px sans-serif;
  fill: rgb(200, 200, 200);
}

.killfeed .killer.t {
  font: 16px sans-serif;
  fill: rgb(180, 120, 0);
}

.killfeed .killer.ct {
  font: 16px sans-serif;
  fill: rgb(60, 120, 180);
}

.killfeed .victim {
  font: 16px sans-serif;
  fill: rgb(200, 200, 200);
}

.killfeed .victim.t {
  font: 16px sans-serif;
  fill: rgb(180, 120, 0);
}

.killfeed .victim.ct {
  font: 16px sans-serif;
  fill: rgb(60, 120, 180);
}

.killfeed .weapon {
  fill: rgb(200, 200, 200);
}

.c4bomb {
  display: none;
}

.c4bomb .icon path {
  fill: rgb(255, 196, 0);
  stroke: #222;
  stroke-width: 6px;
  paint-order: stroke;
}

.c4bomb.defusing .icon path {
  fill: rgb(0, 188, 255);
}

.c4bomb .detonation {
  display: none;
  fill: rgb(255, 196, 0);
  opacity: 0.3;
}

.c4bomb .timer {
  fill: none;
  stroke: rgb(255, 196, 0);
  stroke-width: 2;
  transform: rotate(270deg);
}

/*
.c4bomb .timer.fuse {}
.c4bomb .timer.plant {}
*/
.c4bomb .timer.defuse {
  stroke: rgb(0, 188, 255);
}

.loading_bg {
  fill: black;
}

.loading_text {
  transform: translate(50%, 50%);
}

.diagonalHatch {
  stroke: black;
  stroke-width: 1;
}
