html {
  overflow-y: scroll;
}

body {
  margin: 0;
  background-color: #1f222b;
  color: #71828c;
}

.cdn-error main {
  margin: auto;
  margin-top: 200px;
  max-width: 500px;
}

.cdn-error h1 {
  font-size: 32px;
  font-weight: normal;
  font-family: Archive, sans-serif;
  color: #ffffff;
}

.cdn-error p {
  font-size: 20px;
  font-family: Assistant, sans-serif;
  font-weight: 500;
}

.cdn-error a {
  color: #71828c;
}

.cdn-error a:visited {
  color: #71828c;
}

@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: '<number>';
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
}
