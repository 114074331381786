.user circle.position {
  fill: rgb(0, 255, 0) !important;
  stroke: #111 !important;
  stroke-width: 1px !important;
}

.user .dead polygon.grave {
  stroke: rgb(0, 255, 0) !important;
}

.user .fill {
  fill: rgb(0, 255, 0) !important;
  opacity: 1 !important;
}
