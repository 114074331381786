#timer {
  font-size: 25px;
  font-weight: bold;
  fill: #fff;
}

#timer.end {
  fill: #039e00;
}

#timer.bomb {
  fill: #c90000;
}

#timer.freezetime {
  fill: #808080;
}

#timer.regular {
  fill: #d1d1d1;
}

#rounds {
  display: none;
}

#rounds .buttonbg {
  fill: #444;
  stroke: #000;
  stroke-width: 1px;
}

#rounds .button:hover .buttonbg {
  fill: #555;
}

#rounds .button text {
  font-size: 21px;
  fill: #fff;
}

#rounds.round1 .round1 .buttonbg,
#rounds.round2 .round2 .buttonbg,
#rounds.round3 .round3 .buttonbg,
#rounds.round4 .round4 .buttonbg,
#rounds.round5 .round5 .buttonbg,
#rounds.round6 .round6 .buttonbg,
#rounds.round7 .round7 .buttonbg,
#rounds.round8 .round8 .buttonbg,
#rounds.round9 .round9 .buttonbg,
#rounds.round10 .round10 .buttonbg,
#rounds.round11 .round11 .buttonbg,
#rounds.round12 .round12 .buttonbg,
#rounds.round13 .round13 .buttonbg,
#rounds.round14 .round14 .buttonbg,
#rounds.round15 .round15 .buttonbg,
#rounds.round16 .round16 .buttonbg,
#rounds.round17 .round17 .buttonbg,
#rounds.round18 .round18 .buttonbg,
#rounds.round19 .round19 .buttonbg,
#rounds.round20 .round20 .buttonbg,
#rounds.round21 .round21 .buttonbg,
#rounds.round22 .round22 .buttonbg,
#rounds.round23 .round23 .buttonbg,
#rounds.round24 .round24 .buttonbg,
#rounds.round25 .round25 .buttonbg,
#rounds.round26 .round26 .buttonbg,
#rounds.round27 .round27 .buttonbg,
#rounds.round28 .round28 .buttonbg,
#rounds.round29 .round29 .buttonbg,
#rounds.round30 .round30 .buttonbg {
  fill: rgb(180, 0, 0);
  stroke: #000;
  stroke-width: 1px;
}

#rounds.round1 .round1:hover .buttonbg,
#rounds.round2 .round2:hover .buttonbg,
#rounds.round3 .round3:hover .buttonbg,
#rounds.round4 .round4:hover .buttonbg,
#rounds.round5 .round5:hover .buttonbg,
#rounds.round6 .round6:hover .buttonbg,
#rounds.round7 .round7:hover .buttonbg,
#rounds.round8 .round8:hover .buttonbg,
#rounds.round9 .round9:hover .buttonbg,
#rounds.round10 .round10:hover .buttonbg,
#rounds.round11 .round11:hover .buttonbg,
#rounds.round12 .round12:hover .buttonbg,
#rounds.round13 .round13:hover .buttonbg,
#rounds.round14 .round14:hover .buttonbg,
#rounds.round15 .round15:hover .buttonbg,
#rounds.round16 .round16:hover .buttonbg,
#rounds.round17 .round17:hover .buttonbg,
#rounds.round18 .round18:hover .buttonbg,
#rounds.round19 .round19:hover .buttonbg,
#rounds.round20 .round20:hover .buttonbg,
#rounds.round21 .round21:hover .buttonbg,
#rounds.round22 .round22:hover .buttonbg,
#rounds.round23 .round23:hover .buttonbg,
#rounds.round24 .round24:hover .buttonbg,
#rounds.round25 .round25:hover .buttonbg,
#rounds.round26 .round26:hover .buttonbg,
#rounds.round27 .round27:hover .buttonbg,
#rounds.round28 .round28:hover .buttonbg,
#rounds.round29 .round29:hover .buttonbg,
#rounds.round30 .round30:hover .buttonbg {
  fill: rgb(220, 0, 0);
}
