.player.ct circle.timer {
  fill: rgba(60, 120, 180, 0.1);
  stroke: rgba(60, 120, 180, 0.6);
  stroke-width: 8px;
}

.player.ct circle.position {
  fill: rgb(0, 188, 255);
}

.player.ct rect.shot {
  fill: url(#shotgradient_alt);
}

.player.ct polygon.grave {
  fill: rgb(0, 188, 255);
}

.player.ct .health .fill {
  fill: rgb(0, 150, 0);
}

.player.ct circle.user {
  fill: rgb(0, 188, 255);
  stroke-width: 1px;
}

.player.ct .weapon {
  fill: rgb(60, 120, 180);
}
