#buttons .button {
  cursor: pointer;
}

#buttons .buttonbg {
  fill: rgb(180, 0, 0);
  stroke: #000;
  stroke-width: 1px;
}
#buttons .inactive .buttonbg {
  fill: rgb(0, 0, 0);
}
#buttons .active .buttonbg {
  fill: rgb(180, 0, 0);
}

#buttons .button:hover .buttonbg {
  fill: rgb(220, 0, 0);
}

#buttons .fill {
  fill: #fff;
}

#buttons .outline {
  stroke: #fff;
  stroke-width: 2px;
  fill: none;
}

#buttons .pause {
  display: none;
}

#buttons .playing .play {
  display: none;
}

#buttons .playing .pause {
  display: contents;
}

#buttons .labelbg {
  fill: #444;
  stroke: #000;
  stroke-width: 1px;
}

#buttons .label text {
  font-size: 21px;
  fill: #fff;
}

#buttons .buttonlabel {
  font-size: 16px;
  fill: #fff;
}
